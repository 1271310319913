import React, { useEffect, useState } from 'react'
import { List, Layout, Typography, Tooltip, Skeleton, message, Modal } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { formatErrors, capitalizeFirstLetter } from 'Utils'

import { Analytics } from '@styled-icons/ionicons-sharp/Analytics'

import { computeTagColor, computeActions, actionIcons, computeActionColor } from './computations'
import StatusTag from 'Components/StatusTag'
import KeyMetrics from 'Components/Charts/KeyMetrics'

import './index.scss'

const { error, success } = message
const { Title, Text } = Typography
const { Sider } = Layout

const CJLeftSideBar = ({ CJAnalyticsStates, handleUpdateCJ, CJStates, setCJActions, getCJ }) => {
  const history = useHistory()
  const { collapsed, cj_metrics } = CJAnalyticsStates
  const { id, name } = CJStates
  const [isSubmitting, setIsSubmitting] = useState(['type', false])

  const handleStatusAction = async (action) => {
    setIsSubmitting([action, true])

    const resp = await setCJActions({ id, action })

    if (resp.errors) error(formatErrors(resp.errors, 1))

    if (resp.ok) {
      success(`Customer journey is now ${resp.status}`, 2)
      await getCJ(id)
    }

    setIsSubmitting([action, false])
  }

  const renderActionBtns = () => {
    if (!CJStates?.status) return

    const actions = computeActions(CJStates?.status) || []
    if (!Array.isArray(actions)) return

    return isSubmitting[1] ? (
      <Skeleton.Button shape="circle" active={true} size="large"></Skeleton.Button>
    ) : (
      actions.map((action, i) => (
        <Tooltip key={i} title={`${capitalizeFirstLetter(action)} CJ`}>
          <span
            loading={isSubmitting[0] === action && isSubmitting[1]}
            onClick={() => {
              if (CJStates.isCJModified) {
                return Modal.confirm({
                  title: (
                    <div className="action-prompt-popup">
                      <Title level={4}>{name} is not updated</Title>
                      <Text className="prompt-description">
                        Update customer journey before you execute any action
                      </Text>
                    </div>
                  ),
                  onOk: async () => {
                    await handleUpdateCJ()
                    await handleStatusAction(action)
                  },
                  onCancel: () => {},
                  okText: `Update and ${action}`,
                  okType: 'primary',
                  closable: true,
                  centered: true,
                })
              }

              if (action === 'stop') {
                return Modal.confirm({
                  title: (
                    <div className="action-prompt-popup">
                      <Title level={4}>Are you sure to stop {name}?</Title>
                      <Text className="prompt-description">
                        All existing Tracking ID(s) will be dropped when the customer journey is
                        stopped
                      </Text>
                    </div>
                  ),
                  onOk: async () => handleStatusAction(action),
                  onCancel: () => {},
                  okText: `Stop`,
                  okType: 'danger',
                  closable: true,
                  centered: true,
                })
              }

              return handleStatusAction(action)
            }}
            className={`cj-actions__button ${computeActionColor(action)}`}
          >
            {actionIcons[action]}
          </span>
        </Tooltip>
      ))
    )
  }

  return (
    <div className="CJLeftSideBarComponent">
      <Sider collapsed={collapsed} className="sidebar-wrapper">
        <div>
          <div className="cj-actions-wrapper shadow-div_b pb-4">
            <StatusTag
              color={computeTagColor(CJStates.status)}
              description={'Laborum dolore dolor ea eiusmod ut dolore enim ut reprehenderit.'}
              status={CJStates.status}
            />
            <div className="cj-actions">{renderActionBtns()}</div>
          </div>
          <div className="cj-metrics">
            <KeyMetrics fields={cj_metrics.map((metric) => metric.key)} data={cj_metrics} />
          </div>
        </div>
        <Tooltip title="Analytics">
          <span
            onClick={() => history.push(`${history.location.pathname}/analytics`)}
            className="cj-analytics-button"
          >
            <Analytics />
          </span>
        </Tooltip>
      </Sider>
    </div>
  )
}

export default connect(
  (state) => ({
    CJAnalyticsStates: state.CJAnalytics,
    CJStates: state.customerJourney,
  }),
  (dispatch) => ({
    setCJAnalyticsStates: dispatch.CJAnalytics.setCJAnalyticsStates,
    setCJActions: dispatch.customerJourney.setCJActions,
  })
)(CJLeftSideBar)
