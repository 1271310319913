import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, message, Layout, Typography, Input } from 'antd'
import { CaretDownOutlined, CaretLeftFilled } from '@ant-design/icons'
import { connect } from 'react-redux'
import classNames from 'classnames'

import CJRightSideBar from './CJRightSideBar/index.js'
import CJLeftSideBar from './CJLeftSideBar/index.js'
import CJInfos from './CJInfos'
import { formatErrors } from 'Utils'
import CustomerJourneyDesign from 'Containers/CustomerJourneyDesign'
// import DropDownMenus from './DropDownMenus'
import './index.scss'

const { success, error } = message
const { Title, Text } = Typography

const CJLayout = (props) => {
  const history = useHistory()
  const {
    setCJStates,
    createCJ,
    updateCJ,
    CJStates,
    id,
    getCJ,
    setIsFetchingCJ,
    resetNodeConfigStates,
    setCJActions,
    CJAnalyticsStates,
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    resetNodeConfigStates()
    if (id) {
      setIsFetchingCJ(true)
      getCJ(id)
    }
  }, [])

  const showConfirmUpdateModal = () => {
    Modal.confirm({
      title: (
        <div className="action-prompt-popup">
          <Title level={4}>Are you sure to update {CJStates.name}?</Title>
          <Text className="prompt-description">
            {/* Update customer journey before you execute any action */}
          </Text>
        </div>
      ),

      onOk: async () => {
        await handleUpdateCJ()
      },
      onCancel: () => {
        setIsSubmitting(false)
      },
      okText: `Update`,
      okType: 'primary',
      closable: true,
      centered: true,
    })
  }

  const onSubmit = () => {
    setCJStates({ key: 'status', value: 'init' })
    setIsSubmitting(true)
    id ? showConfirmUpdateModal() : handleCreateCJ()
    setIsSubmitting(false)
  }

  const onDraftSubmit = () => {
    setCJStates({ key: 'status', value: 'draft' })
    setIsSubmitting(true)
    id ? showConfirmUpdateModal() : handleCreateCJ()
    setIsSubmitting(false)
  }

  const handleCreateCJ = async () => {
    try {
      const resp = await createCJ()
      history.push(`/customer-journeys/${resp.id}`)
      setIsSubmitting(false)
      return success('Customer journey has been created')
    } catch (err) {
      return error(formatErrors(err.errors))
    }
  }

  const handleUpdateCJ = async () => {
    try {
      const resp = await updateCJ(id)
      if (resp.errors) throw new Error(resp)
      if (resp.ok) {
        setIsSubmitting(false)
        return success('Customer journey has been updated')
      }
    } catch (err) {
      return error(formatErrors(err.errors))
    }
  }

  const renderCJRightSidebar = useMemo(() => {
    return <CJRightSideBar {...props} />
  }, [CJAnalyticsStates.node_id])
  const isDisablePublish = (id && !CJStates.isCJModified) || CJStates?.status == 'running'
  return (
    <Layout id="cj-layout">
      <div className="cj-left-sidebar">
        {CJStates.id && <CJLeftSideBar handleUpdateCJ={handleUpdateCJ} {...props} />}
      </div>
      <div className="cj-right-sidebar">{CJAnalyticsStates.node_id && renderCJRightSidebar}</div>
      <Layout.Header className="cj-layout-header">
        <Button
          type="secondary"
          icon={<CaretLeftFilled />}
          className="cj-actions__close"
          onClick={() => (window.location.href = '/customer-journeys')}
        >
          Back to list
        </Button>
        {/* <div className="dropdown-container">
          {DropDownMenus.map((item, index) => (
            <Dropdown key={index} trigger={['click']} overlay={item.component}>
              <p>{item.title}</p>
            </Dropdown>
          ))}
        </div> */}
        <div className="cj-name">
          <span
            className="cj-name__title text-left w-full"
            role="textbox"
            contentEditable
            suppressContentEditableWarning
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.target.textContent.length > 30) {
                e.preventDefault()
              }
            }}
            onBlur={(e) => {
              id && setCJStates({ key: 'isCJModified', value: true })
              setCJStates({ key: 'name', value: e.target.textContent })
            }}
          >
            {CJStates.name}
          </span>
          <Input
            value={CJStates?.description}
            bordered={false}
            style={{ width: '100%', maxWidth: '1000px', paddingLeft: '0px' }}
            placeholder="Enter description..."
            onChange={(e) => {
              e.preventDefault()
              e.stopPropagation()
              id && setCJStates({ key: 'isCJModified', value: true })
              setCJStates({ key: 'description', value: e.target.value })
            }}
          ></Input>
          {/* <CaretDownOutlined onClick={() => setIsModalVisible(true)} className="cj-name__down" /> */}
        </div>
        <div className="cj-actions">
          <Button
            // disabled={(id && !CJStates.isCJModified) || CJStates?.status == 'running'}
            disabled={CJStates?.status == 'running'}
            loading={isSubmitting}
            onClick={onDraftSubmit}
            className={classNames('cj-actions__primary mr-2', {
              // 'is-modified': id && CJStates.isCJModified,
              // 'to-background_disabled': isDisablePublish,
            })}
            // className="cj-actions__primary"
            type="primary"
          >
            Save as draft
          </Button>
          <Button
            // disabled={(id && !CJStates.isCJModified) || CJStates?.status == 'running'}
            disabled={CJStates?.status == 'running'}
            loading={isSubmitting}
            onClick={onSubmit}
            className={classNames('cj-actions__primary', {
              // 'is-modified': id && CJStates.isCJModified,
              // 'to-background_disabled': isDisablePublish,
            })}
            // className="cj-actions__primary"
            type="primary"
          >
            Publish
          </Button>
        </div>
      </Layout.Header>

      <Layout.Content className="cj-layout-content">
        <CustomerJourneyDesign id={id} isRunning={['running'].includes(CJStates.status)} />
      </Layout.Content>
      <CJInfos {...props} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </Layout>
  )
}

export default connect(
  (state) => ({
    CJStates: state.customerJourney,
    CJAnalyticsStates: state.CJAnalytics,
  }),
  (dispatch) => ({
    setIsFetchingCJ: dispatch.customerJourney.setIsFetchingCJ,
    setCJStates: dispatch.customerJourney.setCJStates,
    createCJ: dispatch.customerJourney.createCJ,
    getCJ: dispatch.customerJourney.getCJ,
    updateCJ: dispatch.customerJourney.updateCJ,
    resetNodeConfigStates: dispatch.customerJourney.resetNodeConfigStates,
    resetState: dispatch.customerJourney.resetState,
  })
)(CJLayout)
